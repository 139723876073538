import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

import DashboardLayout from 'src/layouts/dashboard';
import Protected from 'src/utils/checkpermission';

export const IndexPage = lazy(() => import('src/pages/app'));
export const EmploeesPage = lazy(() => import('src/pages/users/main'));
export const EmploeesDataPage = lazy(() => import('src/pages/users/user'));
export const AddUserPage = lazy(() => import('src/pages/users/adduser'));
export const UserProfilePage = lazy(() => import('src/pages/users/userprofile'));


export const Mainstudents = lazy(() => import('src/pages/students/main'))
export const AddStudents = lazy(() => import('src/pages/students/addStudent'))
export const ReportStudents = lazy(() => import('src/pages/evaluation/reports-page'))
export const Students = lazy(() => import('src/pages/students/students'))

// Speacial Edu
export const StudentSE = lazy(() => import('src/pages/se/studentSe'))
export const ObjectLibrarySE = lazy(() => import('src/pages/se/categorySe'))
export const PEvalStudents = lazy(() => import('src/pages/se/preEvaluation'))
export const AddPEvalStudents = lazy(() => import('src/pages/se/addPreEval'))
export const StudentPlan = lazy(() => import('src/pages/se/plan'))
export const AddItemToPlan = lazy(() => import('src/pages/se/AddItemToPlan'))
export const AddResponseToPlan = lazy(() => import('src/pages/se/AddResponseToPlan'))
export const SeDepart = lazy(() => import('src/pages/departments/seDepart'))

// Sport
export const SportDepart = lazy(() => import('src/pages/departments/sportDepart'))
export const SportStudent = lazy(() => import('src/pages/sport/studentSport'))
export const SportObjectLibrary = lazy(() => import('src/pages/sport/categorySport'))
export const SportPEval = lazy(() => import('src/pages/sport/preEvaluation'))
export const SportAddPreEval = lazy(() => import('src/pages/sport/addPreEval'))
export const SportPlan = lazy(() => import('src/pages/sport/plan'))
export const AddItemToSportPlan = lazy(() => import('src/pages/sport/AddItemToPlan'))
export const AddResponSportToPlan = lazy(() => import('src/pages/sport/AddResponseToPlan'))

// Physiotherapy
export const PhDepart = lazy(() => import('src/pages/departments/phDepart'))
export const PhStudent = lazy(() => import('src/pages/Physiotherapy/studentPh'))
export const phPEval = lazy(() => import('src/pages/Physiotherapy/phPlan'))
export const PhPlan = lazy(() => import('src/pages/Physiotherapy/phPlan'))
export const AddPhPlanItem = lazy(() => import('src/pages/Physiotherapy/addPhPlanItems'))
export const PhSession = lazy(() => import('src/pages/Physiotherapy/phSession'))
export const AddPhSessionItem = lazy(() => import('src/pages/Physiotherapy/addPhSessionItems'))


// export const SportAddPreEval = lazy(() => import('src/pages/sport/addPreEval'))

// Workshop
export const WorkshopDepart = lazy(() => import('src/pages/departments/workShopDepart'))
export const WorkshopStudent = lazy(() => import('src/pages/workshop/student'))
export const WorkshopObjectLibrary = lazy(() => import('src/pages/workshop/category'))
export const WorkshopPEval = lazy(() => import('src/pages/workshop/preEvaluation'))
export const WorkshopAddPreEval = lazy(() => import('src/pages/workshop/addPreEval'))
export const WorkshopPlan = lazy(() => import('src/pages/workshop/plan'))
export const AddItemToWorkshopPlan = lazy(() => import('src/pages/workshop/AddItemToPlan'))
export const AddResponWorkshopToPlan = lazy(() => import('src/pages/workshop/AddResponseToPlan'))


// Housing
export const HousingDepart = lazy(() => import('src/pages/departments/housingDepart'))
export const HousingServReg = lazy(() => import('src/pages/departments/housingServReg'))
export const HousingVillas = lazy(() => import('src/pages/housing/villas'))
export const StudentHouse = lazy(() => import('src/pages/housing/studenthouse'))
export const StudentEnterHouse = lazy(() => import('src/pages/housing/studententerhouse'))
export const StudentTransHouseAdd = lazy(() => import('src/pages/housing/studenttransaddhouse'))
export const HouseDailyReg = lazy(() => import('src/pages/housing/housedaliyreg'))
export const HouseWeeklyReg = lazy(() => import('src/pages/housing/houseweeklyreg'))
export const HouseServiceRegPrint = lazy(() => import('src/pages/housing/homeserviceprint'))

export const Permission = lazy(() => import('src/pages/permissions/permissions'))
export const MainDeparts = lazy(() => import('src/pages/departments/main'))

// SpeachDepart
export const SpeachDepart = lazy(() => import('src/pages/departments/speachDepart'))
export const StudentSpeach = lazy(() => import('src/pages/speach/studentSpeach'))
export const AddPEvalStudentsSpeach = lazy(() => import('src/pages/speach/addPrEval'))
export const PEvalStudentsSpeach = lazy(() => import('src/pages/speach/preEvaluation'))
export const StudentPlanSpeach = lazy(() => import('src/pages/speach/plan'))
export const AddItemToPlanSpeach = lazy(() => import('src/pages/speach/AddItemToPlan'))
export const AddResponseToPlanSpeach = lazy(() => import('src/pages/speach/AddResponseToPlan'))
export const ObjectLibrarySpeach = lazy(() => import('src/pages/speach/categorySpeach'))


export const LoginPage = lazy(() => import('src/pages/login'));
export const Page404 = lazy(() => import('src/pages/page-not-found'));

//Purchases
export const PurchasesDepart = lazy(() => import('src/pages/departments/purchasesDepart'))
export const PurchasesProduct = lazy(() => import('src/pages/purchases/prproduct'))
export const PurchasesService = lazy(() => import('src/pages/purchases/prservice'))
export const AddPurchasesProduct = lazy(() => import('src/pages/purchases/addprproduct'))




// ----------------------------------------------------------------------

export default function Router() {
  const userAuth = secureLocalStorage.getItem('object')
  let authRouter = []
  if (userAuth) {
    if (userAuth.token) {
      authRouter = [
        {
          element: (
            <DashboardLayout>
              <Suspense>
                <Outlet />
              </Suspense>
            </DashboardLayout>
          ),
          children:
            [
              { path: '/', element: <IndexPage /> },
              { path: 'userprofile', element: <UserProfilePage /> },
              { path: 'employees', element: <Protected childern={<EmploeesDataPage />} /> },
              { path: 'employees/add', element: <Protected childern={<AddUserPage />} /> },
              { path: 'employeedit/:user_id', element: <Protected childern={<AddUserPage />} /> },
              { path: 'permissions', element: <Protected childern={<Permission />} /> },
              { path: 'students/add', element: <Protected childern={<AddStudents />} /> },
              { path: 'students', element: <Protected childern={<Students />} /> },
              { path: 'students/edit/:student_id', element: <Protected childern={<AddStudents />} /> },
              { path: 'students/reports/:student_id', element: <Protected childern={<ReportStudents />} /> },
              { path: 'departs', element: <Protected childern={<MainDeparts />} /> },
              //SE
              { path: 'departs/se', element: <Protected childern={<SeDepart />} /> },
              { path: 'se/students', element: <Protected childern={<StudentSE />} /> },
              { path: 'se/objectlibrary', element: <Protected childern={<ObjectLibrarySE />} /> },
              { path: 'se/pevaluation', element: <Protected childern={<PEvalStudents />} /> },
              { path: 'se/addpevaluation/:student_id', element: <Protected childern={<AddPEvalStudents />} /> },
              { path: 'se/studentsplan/:student_id', element: <Protected childern={<StudentPlan />} /> },
              { path: 'se/additemtoplan', element: <Protected childern={<AddItemToPlan />} /> },
              { path: 'se/addresponsetoplan/:plan_id', element: <Protected childern={<AddResponseToPlan />} /> },
              //Sport
              { path: 'departs/sport', element: <Protected childern={<SportDepart />} /> },
              { path: 'sport/students', element: <Protected childern={<SportStudent />} /> },
              { path: 'sport/objectlibrary', element: <Protected childern={<SportObjectLibrary />} /> },
              { path: 'sport/pevaluation', element: <Protected childern={<SportPEval />} /> },
              { path: 'sport/addpevaluation/:student_id', element: <Protected childern={<SportAddPreEval />} /> },
              { path: 'sport/studentsplan/:student_id', element: <Protected childern={<SportPlan />} /> },
              { path: 'sport/additemtoplan', element: <Protected childern={<AddItemToSportPlan />} /> },
              { path: 'sport/addresponsetoplan/:plan_id', element: <Protected childern={<AddResponSportToPlan />} /> },
              //Workshop
              { path: 'departs/workshop', element: <Protected childern={<WorkshopDepart />} /> },
              { path: 'workshop/students', element: <Protected childern={<WorkshopStudent />} /> },
              { path: 'workshop/objectlibrary', element: <Protected childern={<WorkshopObjectLibrary />} /> },
              { path: 'workshop/pevaluation', element: <Protected childern={<WorkshopPEval />} /> },
              { path: 'workshop/addpevaluation/:student_id', element: <Protected childern={<WorkshopAddPreEval />} /> },
              { path: 'workshop/studentsplan/:student_id', element: <Protected childern={<WorkshopPlan />} /> },
              { path: 'workshop/additemtoplan', element: <Protected childern={<AddItemToWorkshopPlan />} /> },
              { path: 'workshop/addresponsetoplan/:plan_id', element: <Protected childern={<AddResponWorkshopToPlan />} /> },

              //Physiotherapy
              { path: 'departs/ph', element: <Protected childern={<PhDepart />} /> },
              { path: 'ph/students', element: <Protected childern={<PhStudent />} /> },
              { path: 'ph/studentsplan/:student_id', element: <Protected childern={<PhPlan />} /> },
              { path: 'ph/pevaluation', element: <Protected childern={<AddPhPlanItem />} /> },
              { path: 'ph/studentssession/:student_id', element: <Protected childern={<PhSession />} /> },
              { path: 'ph/addsession', element: <Protected childern={<AddPhSessionItem />} /> },

              //Speach
              { path: 'departs/speach', element: <Protected childern={<SpeachDepart />} /> },
              { path: 'speach/students', element: <Protected childern={<StudentSpeach />} /> },
              { path: 'speach/objectlibrary', element: <Protected childern={<ObjectLibrarySpeach />} /> },
              { path: 'speach/pevaluation', element: <Protected childern={<PEvalStudentsSpeach />} /> },
              { path: 'speach/addpevaluation/:student_id', element: <Protected childern={<AddPEvalStudentsSpeach />} /> },
              { path: 'speach/studentsplan/:student_id', element: <Protected childern={<StudentPlanSpeach />} /> },
              { path: 'speach/additemtoplan/:plan_id', element: <Protected childern={<AddItemToPlanSpeach />} /> },
              { path: 'speach/addresponsetoplan/:plan_id', element: <Protected childern={<AddResponseToPlanSpeach />} /> },

              //Housing
              { path: 'departs/housing', element: <Protected childern={<HousingDepart />} /> },
              { path: 'housing/villas', element: <Protected childern={<HousingVillas />} /> },
              { path: 'housing/students/enthouse', element: <Protected childern={<StudentHouse />} /> },
              { path: 'housing/students/enthouse/add', element: <Protected childern={<StudentEnterHouse />} /> },
              { path: 'housing/students/enthouse/edit/:std_id', element: <Protected childern={<StudentEnterHouse />} /> },
              { path: 'housing/students/enthouse/reject/:std_id', element: <Protected childern={<StudentEnterHouse />} /> },
              { path: 'housing/students/enthouse/approve/:std_id', element: <Protected childern={<StudentEnterHouse />} /> },
              { path: 'housing/students/transhouse/add/:std_id', element: <Protected childern={<StudentTransHouseAdd />} /> },
              { path: 'housing/students/transhouse/edit/:std_id', element: <Protected childern={<StudentTransHouseAdd />} /> },
              { path: 'housing/students/transhouse/reject/:std_id', element: <Protected childern={<StudentTransHouseAdd />} /> },
              { path: 'housing/students/transhouse/approve/:std_id', element: <Protected childern={<StudentTransHouseAdd />} /> },
              { path: 'housing/servicesreg', element: <Protected childern={<HousingServReg />} /> },
              { path: 'housing/servicesreg/daily', element: <Protected childern={<HouseDailyReg />} /> },
              { path: 'housing/servicesreg/weekly', element: <Protected childern={<HouseWeeklyReg />} /> },
              { path: 'housing/servicesreg/print', element: <Protected childern={<HouseServiceRegPrint />} /> },

              //Purchases
              { path: 'pr', element: <Protected childern={<PurchasesDepart />} /> },
              { path: 'pr/prproduct', element: <Protected childern={<PurchasesProduct />} /> },
              { path: 'pr/prservice', element: <Protected childern={<PurchasesService />} /> },
              { path: 'pr/prproduct/add', element: <Protected childern={<AddPurchasesProduct />} /> },
              { path: 'pr/prproduct/edit/:prpd_id', element: <Protected childern={<AddPurchasesProduct />} /> },
              { path: 'pr/prproduct/approve/:prpd_id', element: <Protected childern={<AddPurchasesProduct />} /> },
              { path: 'pr/prproduct/reject/:prpd_id', element: <Protected childern={<AddPurchasesProduct />} /> },






            ]
          ,
        },
        {
          path: '/',
          children: [
            { path: '/', element: <Navigate to="/" /> },
            { path: 'login', element: <Navigate to="/" /> },
            { path: '404', element: <Page404 /> },
            { path: '*', element: <Navigate to="/404" replace /> },
          ],
        },
      ]
    }
  } else {
    authRouter = [
      { path: 'login', element: <LoginPage /> },
      { path: '404', element: <Page404 /> },
      { path: '*', element: <Navigate to="/login" replace /> },
      // {
      //   path: '/',
      //   children: [
      //     { path: '/', element: <Navigate to="/login" /> },
      //     // { path: '*', element: <Navigate to="/login" /> },
      //   ],
      // },
    ]
  }
  const routes = useRoutes(authRouter)
  return routes
}
